import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
    static propTypes = {
        selector: PropTypes.string,
        children: PropTypes.node,
        onReady: PropTypes.func,
    };

    static defaultProps = {
        selector: '#__portal__',
    };

    state = {
        element: null,
    };

    componentDidMount() {
        this.setState({
            element: document.querySelector(this.props.selector),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.element && this.state.element && this.props.onReady) {
            this.props.onReady();
        }
    }

    render() {
        const { element } = this.state;

        if (element) {
            return ReactDOM.createPortal(this.props.children, element);
        }

        return null;
    }
}

export default Portal;
