import React from 'react';
import cx from 'classnames';
import Portal from 'components/containers/Portal';

import grid from 'styles/grid.scss';
import styles from './GridOverlay.scss';

class GridOverlay extends React.Component {
    state = {
        visible: false,
    };

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.addEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress = event => {
        if (
            event.target.nodeName.toLowerCase() !== 'input' &&
            event.keyCode === 103
        ) {
            this.setState(state => ({
                visible: !state.visible,
            }));
        }
    };

    render() {
        const className = cx(styles.gridOverlay, grid.container, {
            [styles.visible]: this.state.visible,
        });
        return (
            <Portal selector="#__foreground_portal__">
                <div className={className}>
                    {Array.from({ length: 12 }).map((_, i) => {
                        const className = cx(styles.col, styles[`col${i + 1}`]);
                        return (
                            <div key={i} className={className} />
                        );
                    })}
                </div>
            </Portal>
        );
    }
}

export default GridOverlay;
