import React from 'react';
import Router from 'next/router';
import cx from 'classnames';
import { Provider } from 'react-redux';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import withRedux from 'next-redux-wrapper';
import FontFaceObserver from 'fontfaceobserver';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Transition from 'react-transition-group/Transition';
import { TunnelProvider } from 'react-tunnels';

import { activateWebFontTracking, pageView, detectTouch } from 'utils';
import { gaTag } from 'utils/constants';

import AppLayout from 'components/ui/AppLayout';
import GridOverlay from 'components/ui/GridOverlay';
import PageLoader from 'components/ui/PageLoader';

import { makeStore, fetchAllPages } from 'store';

import KaleidoscopeContext, {
    controller,
} from 'components/ui/Kaleidoscope/Context';

const Kaleidoscope = dynamic(() => import('components/ui/Kaleidoscope'), {
    loading: () => null,
    ssr: false,
});

class DarkSideApp extends App {
    static async getInitialProps({ Component, ctx }) {
        await ctx.store.dispatch(fetchAllPages());
        const { pages } = ctx.store.getState();
        return {
            pageProps: {
                landing: pages['/'],
                  studio: pages['/home'],
                  work: pages['/work'],
                              contact: pages['/contact'],
                ...(Component.getInitialProps
                    ? await Component.getInitialProps(ctx)
                    : {}),
            },
        };
    }

    state = {
        isTouch: true,
        fontsLoaded: false,
        kaleidoscopeController: controller,
    };

    componentDidMount() {
        // Load fonts
        const futuraBold = new FontFaceObserver('Futura Maxi W05 Bold');
        const recoletaMedium = new FontFaceObserver('Recoleta W05 Medium');
        Promise.all([futuraBold.load(), recoletaMedium.load()]).then(() => {
            this.setState({
                fontsLoaded: true,
            });
        });

        // Needed for legal reasons (font licensing)
        activateWebFontTracking();

        // Add GA
        Router.events.on('routeChangeComplete', url => pageView(url, gaTag));

        // Detect touch vs mouse
        detectTouch(isTouch => this.setState({ isTouch }));
    }

    componentDidUpdate() {
        this.prevAsPath = this.props.router.asPath;
    }

    handleKaleidoscopeReady = controller => {
        this.setState(state => {
            return {
                kaleidoscopeController: {
                    ...state.kaleidoscopeController,
                    ...controller,
                },
            };
        });
    };

    renderHead() {
        return (
            <Head>
                <title>Deinde</title>
            </Head>
        );
    }

    renderKaleidoscope() {
        return (
            <Kaleidoscope
                pageProps={this.props.pageProps}
                onReady={this.handleKaleidoscopeReady}
            />
        );
    }

    renderPageComponent() {
        const { isTouch } = this.state;
        const { Component, pageProps, router } = this.props;
        const hasTransition = router.query.transition !== 'nope';
        const key = hasTransition ? router.asPath : this.prevAsPath;

        return (
            <AppLayout>
                <TransitionGroup component={null}>
                    <Transition key={key} timeout={500}>
                        {status => {
                            const className = cx('page', `page-${status}`, {
                                'page-work':
                                    pageProps.contentTypeId ===
                                    'workPageContent',
                            });
                            return (
                                <div className={className}>
                                    <PageLoader />
                                    <Component
                                        {...pageProps}
                                        isTouch={isTouch}
                                        transitionStatus={status}
                                        hasTransition={hasTransition}
                                        fontsLoaded={this.state.fontsLoaded}
                                    />
                                </div>
                            );
                        }}
                    </Transition>
                </TransitionGroup>
            </AppLayout>
        );
    }

    renderGridOverlay() {
        if (process.env.NODE_ENV === 'production') {
            return null;
        }
        return <GridOverlay />;
    }

    render() {
        const { store } = this.props;
        const { kaleidoscopeController: context } = this.state;

        return (
            <React.Fragment>
                {this.renderHead()}
                <TunnelProvider>
                    <KaleidoscopeContext.Provider value={context}>
                        {this.renderKaleidoscope()}
                        <Provider store={store}>
                            {this.renderPageComponent()}
                        </Provider>
                    </KaleidoscopeContext.Provider>
                </TunnelProvider>
                {this.renderGridOverlay()}
            </React.Fragment>
        );
    }
}

export default withRedux(makeStore)(DarkSideApp);
