import { Component } from 'react';
import Router from 'next/router';

import NProgress from 'nprogress';

import styles from './PageLoader.scss';

NProgress.configure({
    showSpinner: false,
    template: `<div class=${styles.loader} role="bar"></div>`,
});

class PageLoader extends Component {
    componentDidMount() {
        Router.onRouteChangeStart = () => {
            NProgress.start();
        };
        Router.onRouteChangeComplete = () => {
            NProgress.done();
        };
        Router.onRouteChangeError = () => {
            NProgress.done();
        };
    }

    componentWillUnmount() {
        Router.onRouteChangeStart = null;
        Router.onRouteChangeComplete = null;
        Router.onRouteChangeError = null;
    }

    render() {
        return null;
    }
}

export default PageLoader;
